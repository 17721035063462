import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  VStack,
  Text,
  Select,
  Button,
  HStack,
  Center,
  Divider,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import DirectorsAndOfficersInfo from "./provincial/Directors";
import AccountInformation from "./AccountInformation";
// import ShareStructure from "./provincial/ShareStructure";
import AddOns from "./provincial/AddOns";
import AgentForService from "./provincial/AgentForService";
import Type from "./partner/Type";
import PartnersInfo from "./partner/PartnersInfo";
import ShareHolders from "./provincial/shareholders";
import Payment from "./Payment";
import Bylaws from "./provincial/ByLaws";
import Agreement from "./partner/Agreement";
import { OfficersInfo } from "./provincial/Officers";
import UnanimousShareholderAgreement from "./provincial/UnanimousShareholderAgreement";
import Articles from "./provincial/Articles";
import BusinessInformation from "./BusinessInformation";
import MinuteBook from "./provincial/MinuteBook";
import { snappyCorpPriceList } from "../../../services/price";
import Review from "./Review";

const CompanyComponent = ({ bType }) => {
  const [companyType, setCompanyType] = useState(bType);
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const [request, setRequest] = useState({
    serviceType: companyType,
    price:
      companyType === "partnership"
        ? snappyCorpPriceList.GeneralPartnership
        : companyType === "named"
        ? snappyCorpPriceList.Named
        : companyType === "numbered"
        ? snappyCorpPriceList.Numbered
        : snappyCorpPriceList.SoleProprietorship,
    prices: {
      default:
        companyType === "partnership"
          ? snappyCorpPriceList.GeneralPartnership
          : companyType === "named"
          ? snappyCorpPriceList.Named
          : companyType === "numbered"
          ? snappyCorpPriceList.Numbered
          : snappyCorpPriceList.SoleProprietorship,
      AgentForService: {
        value: snappyCorpPriceList.SnappyAgent,
        enabled: false,
        active:
          companyType  !=="soleProprietorship" && companyType  !=="partnership"
            ? true
            : false,
      },
      RegisterShareholders: {
        value: snappyCorpPriceList.ChangeShareholders,
        enabled: false,
        active:
          companyType  !=="soleProprietorship" && companyType  !=="partnership"
            ? true
            : false,
      },
      SubscribeAnnualMaintenance: {
        value: snappyCorpPriceList.SnappyAnnualSubscription,
        enabled: false,
        active:
          companyType  !=="soleProprietorship" && companyType  !=="partnership"
            ? true
            : false,
      },
      MeetingMinutes: {
        value: snappyCorpPriceList.MinuteBook,
        enabled: false,
        active:
          companyType  !=="soleProprietorship" && companyType  !=="partnership"
            ? true
            : false,
      },
      UnanimousShareholderAgreement: {
        value: snappyCorpPriceList.UnanimousShareholderAgreement,
        enabled: false,
        active:
          companyType  !=="soleProprietorship" && companyType  !=="partnership"
            ? true
            : false,
      },
      PartnershipAgreement: {
        value: snappyCorpPriceList.PartnershipAgreement,
        enabled: false,
        active:
          companyType ==="partnership"
            ? true
            : false,
      },
      FederalDiscount: {
        value: snappyCorpPriceList.FederalDiscount,
        enabled: false,
        active: true
      },
    },
    accountInfo: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      occupation: "",
      relation: companyType == "soleProprietorship" ? "owner" : "agent for service",
      streetLine1: "",
      streetLine2: "",
      city: "",
      province: "AB",
      country: "Canada",
      postalCode: ""
    },
    businessInfo: {
      type: "",
      suffix: "",
      businessName: "",
      businessEmail: "",
      NamePreference1: "",
      NamePreference2: "",
      NamePreference3: "",
      preference1Suffix: "",
      preference2Suffix: "",
      preference3Suffix: "",
      homeJurisdiction: "NB",
      homeJurisdiction_RegNo: "",
      homeJurisdiction_BusNo: "",
      streetLine1: "",
      streetLine2: "",
      city: "",
      province: "AB",
      country: "Canada",
      postalCode: ""
    },
    directors: {
      officershipType: "By Myself",
      managers: ""
    },
    // officers: {
    //   managers: ""
    // },
    shareholders: {
      currentShareholders: []
    },
    // shareStructure: "",
    articlesInfo: {
      isSnappyCorp: false,
      articleRequirements: "",
      minDirector: 1,
      maxDirector: 15,
      classOfShares:
        'The corporation is authorized to issue shares in accordance with Schedule "A" attached hereto, which is incorporated into and forms part of this form.',
      restrictShareTransfer:
        "No shares of this corporation shall be transferred without the approval of the directors of the corporation, as evidenced by a Resolution of the Directors of the corporation.",
      restrictBusinessArea: "NA",
      otherRulesProv: 'Other rules or provisions to be included in Schedule "B" attached hereto is incorporated into and forms part of this form.',
    },
    agent: {
      firstName: "",
      lastName: "",
      email: "",
      streetLine1: "",
      streetLine2: "",
      city: "Calgary",
      province: "AB",
      country: "Canada",
      postalCode: ""
    },
    partnerInfo: {
      partnershipType: "General",
      partnershipAgreement: "",
      partnershipAgreementRequirements: "",
      partnershipCommencementDate: "",
      partnershipExpiryDate: "Never",
      partners: "",
    },
    minuteBookInfo: {
      bylaws: "",
      unanimousShareholderAgreement: "",
      unanimousShareholderAgreementRequirements: "",
      officers: ""
    },
    
    minuteBook: false,
    annualMgmt: false,

    yesno: {
      // ShareStructure_Own: { enabled: false },
      // ShareStructure_Template: { enabled: false },
      ByLaws_Own: { enabled: false },
      ByLaws_Template: { enabled: false },
      UnanimousShareholderAgreement_Own: { enabled: false },
      PartnershipAgreement_Own: { enabled: false },
      PartnershipExpiry: { enabled: false },
      Officers: { enabled: false },
      Articles_Own: { enabled: false },
      Articles_Template: { enabled: false }
    },

    review: {
      named: [
        'accountInfo',
        'businessInfo',
        'articlesInfo',
        'directors',
        'shareholders',
        'agent',
        'minuteBookInfo'
      ],
      numbered: [
        'accountInfo',
        'businessInfo',
        'articlesInfo',
        'directors',
        'shareholders',
        'agent',
        'minuteBookInfo'
      ],
      partnership: [
        "accountInfo",
        "businessInfo",
        "partnerInfo"
      ],
      soleProprietorship: [
        "accountInfo",
        "businessInfo"
      ]
    },

    reviewFieldNotRequired: {
      named: {
        accountInfo: {
          streetLine2: false
        },
        businessInfo: {
          streetLine2: false
        },
        agent: {
          streetLine2: false
        },
        minuteBookInfo: {
          unanimousShareholderAgreement: false,
          unanimousShareholderAgreementRequirements: false
        }
      },
      numbered: {
        accountInfo: {
          streetLine2: false
        },
        businessInfo: {
          streetLine2: false
        },
        agent: {
          streetLine2: false
        },
        minuteBookInfo: {
          unanimousShareholderAgreement: false,
          unanimousShareholderAgreementRequirements: false
        }
      },
      partnership: {
        accountInfo: {
          streetLine2: false
        },
        businessInfo: {
          streetLine2: false
        },
        partnerInfo: {
          partnershipAgreementRequirements: false
        }
      },
      soleProprietorship: {
        accountInfo: {
          streetLine2: false
        },
        businessInfo: {
          streetLine2: false
        }
      }
    },
    
    reviewFieldHidden: {
      named: {
        accountInfo: {
          occupation: false
        },
        businessInfo: {
          type: false,
          businessName: false,
          homeJurisdiction: false,
          homeJurisdiction_RegNo: false,
          homeJurisdiction_BusNo: false
        },
        articlesInfo: {
          isSnappyCorp: false,
          articleRequirements: false
        },
        directors: {
          managers: false
        },
        minuteBookInfo: {
          officers: false
        }
      },
      numbered: {
        accountInfo: {
          occupation: false
        },
        businessInfo: {
          type: false,
          businessName: false,
          NamePreference1: false,
          NamePreference2: false,
          NamePreference3: false,
          preference1Suffix: false,
          preference2Suffix: false,
          preference3Suffix: false,
          homeJurisdiction: false,
          homeJurisdiction_RegNo: false,
          homeJurisdiction_BusNo: false
        },
        articlesInfo: {
          isSnappyCorp: false,
          articleRequirements: false
        },
        directors: {
          managers: false
        },
        minuteBookInfo: {
          officers: false
        }
      },
      partnership: {
        accountInfo: {
          occupation: false
        },
        businessInfo: {
          type: false,
          suffix: false,
          NamePreference1: false,
          NamePreference2: false,
          NamePreference3: false,
          preference1Suffix: false,
          preference2Suffix: false,
          preference3Suffix: false,
          homeJurisdiction: false,
          homeJurisdiction_RegNo: false,
          homeJurisdiction_BusNo: false
        }
      },
      soleProprietorship: {
        businessInfo: {
          suffix: false,
          NamePreference1: false,
          NamePreference2: false,
          NamePreference3: false,
          preference1Suffix: false,
          preference2Suffix: false,
          preference3Suffix: false,
          homeJurisdiction: false,
          homeJurisdiction_RegNo: false,
          homeJurisdiction_BusNo: false
        }
      }
    },

    reviewFieldReadOnly: {
      named: {
        accountInfo: {
          relation: false
        },
        articlesInfo: {
          isSnappyCorp: false,
          articleRequirements: false,
          classOfShares: false,
          restrictShareTransfer: false,
          restrictBusinessArea: false,
          otherRulesProv: false
        },
        directors: {
          officershipType: false
        },
        minuteBookInfo: {
          bylaws: false,
          unanimousShareholderAgreement: false,
          unanimousShareholderAgreementRequirements: false
        }
      },
      numbered: {
        accountInfo: {
          relation: false
        },
        businessInfo: {
          suffix: false
        },
        articlesInfo: {
          isSnappyCorp: false,
          articleRequirements: false,
          classOfShares: false,
          restrictShareTransfer: false,
          restrictBusinessArea: false,
          otherRulesProv: false
        },
        directors: {
          officershipType: false
        },
        minuteBookInfo: {
          bylaws: false,
          unanimousShareholderAgreement: false,
          unanimousShareholderAgreementRequirements: false
        }
      },
      partnership: {
        accountInfo: {
          relation: false
        },
        businessInfo: {
          homeJurisdiction: false
        },
        partnerInfo: {
          partnershipType: false,
          partnershipAgreement: false,
          partnershipAgreementRequirements: false,
          partnershipExpiryDate: false
        }
      },
      soleProprietorship: {
        accountInfo: {
          relation: false
        }
      }
    },

    reviewFieldCount: 0,
    reviewFieldNoDataCount: 0,

    toggleYesNo: (value, type) => {
      setRequest((prevRequest) => {
        let bool = value ==="Yes" ? true : false;
        let updatedYesNo = prevRequest.yesno;

        updatedYesNo[type].enabled = bool;

        return {
          ...prevRequest,
          yesno: updatedYesNo
        };
      });
    },
    togglePrice: (value, priceType) => {
      setRequest((prevRequest) => {
        let bool = value ==="Yes" ? true : false;
        let updatedPrices = prevRequest.prices;

        updatedPrices[priceType].enabled = bool;

        return {
          ...prevRequest,
          prices: updatedPrices
        };
      });

      request.updatePrice();
    },
    updatePrice: () => {
      setRequest((prevRequest) => {
        let updatedPrice = 0;

        updatedPrice += prevRequest.prices.default;

        for (const key in prevRequest.prices) {
          if (
            prevRequest.prices[key].enabled === true &&
            prevRequest.prices[key].active === true
          ) {
            updatedPrice += prevRequest.prices[key].value;
          }
        }

        return {
          ...prevRequest,
          price: updatedPrice,
        };
      });
    },
    resetPrices: () => {
      setRequest((prevRequest) => {
        const updatedPrice = prevRequest.prices.default;
        const updatedPrices = prevRequest.prices;

        for (const key in prevRequest.prices) {
          if (key  !=="default") {
            updatedPrices[key].enabled = false;
          }
        }

        return {
          ...prevRequest,
          price: updatedPrice,
          prices: updatedPrices,
        };
      });
    },
  });

  const handleTypeChange = (event) => {
    const type = event.target.value;
    const activeAgentForService = type !== "soleProprietorship" && type != 'partnership' ? true : false;
    const activeSubscribeAnnualMaintenance = type !== "soleProprietorship" && type != 'partnership' ? true : false;

    setCompanyType(type);

    setRequest((prevReq) => ({
      ...prevReq,
      serviceType: type,
      accountInfo: {
        ...prevReq.accountInfo,
        relation: type == "soleProprietorship" ? "owner" : "agent for service",
      },
      partnerInfo: {
        ...prevReq.partnerInfo,
        partnershipAgreement: "",
        partnershipAgreementRequirements: ""
      },
      directors: {
        officershipType: "By Myself",
        managers: ""
      },
      shareholders: {
        currentShareholders: []
      },
      articlesInfo: {
        isSnappyCorp: false,
        articleRequirements: "",
        minDirector: 1,
        maxDirector: 15,
        classOfShares:
          'The corporation is authorized to issue shares in accordance with Schedule "A" attached hereto, which is incorporated into and forms part of this form.',
        restrictShareTransfer:
          "No shares of this corporation shall be transferred without the approval of the directors of the corporation, as evidenced by a Resolution of the Directors of the corporation.",
        restrictBusinessArea: "NA",
        otherRulesProv: 'Other rules or provisions to be included in Schedule "B" attached hereto is incorporated into and forms part of this form.',
      },
      agent: {
        firstName: "",
        lastName: "",
        email: "",
        streetLine1: "",
        streetLine2: "",
        city: "Calgary",
        province: "AB",
        country: "Canada",
        postalCode: ""
      },
      partnerInfo: {
        partnershipType: "General",
        partnershipAgreement: "",
        partnershipAgreementRequirements: "",
        partnershipExpiryDate: "Never",
        partnershipCommencementDate: "",
        partners: "",
      },
      minuteBookInfo: {
        bylaws: "",
        unanimousShareholderAgreement: "",
        unanimousShareholderAgreementRequirements: "",
        officers: ""
      },
      minuteBook: false,
      annualMgmt: false,
      price:
        type === "partnership"
          ? snappyCorpPriceList.GeneralPartnership
          : type === "named"
          ? snappyCorpPriceList.Named
          : type === "numbered"
          ? snappyCorpPriceList.Numbered
          : snappyCorpPriceList.SoleProprietorship,
      prices: {
        ...prevReq.prices,
        default:
          type === "partnership"
            ? snappyCorpPriceList.GeneralPartnership
            : type === "named"
            ? snappyCorpPriceList.Named
            : type === "numbered"
            ? snappyCorpPriceList.Numbered
            : snappyCorpPriceList.SoleProprietorship,
        AgentForService: {
          ...prevReq.prices.AgentForService,
          active: activeAgentForService
        },
        SubscribeAnnualMaintenance: {
          ...prevReq.prices.SubscribeAnnualMaintenance,
          active: activeSubscribeAnnualMaintenance
        }
      }
    }));

    setSelectedSectionIndex(0);

    request.resetPrices();
  };

  // Define sections for each company type
  const sectionsMap = {
    named: [
      "accountInformation",
      "businessInformation",
      "articles",
      // "shareStructure",
      "directors",
      "shareholders",
      "agentForService",
      "minuteBook",
      "addOns",
      "review",
      "payment",
    ],
    numbered: [
      "accountInformation",
      "businessInformation",
      "articles",
      // "shareStructure",
      "directors",
      "shareholders",
      "agentForService",
      "minuteBook",
      "addOns",
      "review",
      "payment",
    ],
    partnership: [
      "accountInformation",
      "typeOfPartnership",
      "businessInformation",
      "partners'Information",
      "partnershipAgreement",
      "review",
      "payment",
    ],
    soleProprietorship: [
      "accountInformation",
      "businessInformation",
      "review",
      "payment",
    ],
  };

  const handlePrevious = () => {
    if (sectionsMap[companyType]) {
      setSelectedSectionIndex((prevIndex) => Math.max(0, prevIndex - 1));
      window.scrollTo(0, 0);
    }
  };

  const handleNext = () => {
    if (sectionsMap[companyType]) {
      const sections = sectionsMap[companyType];
      setSelectedSectionIndex((prevIndex) =>
        Math.min(prevIndex + 1, sections.length - 1)
      );
      window.scrollTo(0, 0);
    }
  };

  const renderMainComponent = () => {
    if (sectionsMap[companyType]) {
      const sections = sectionsMap[companyType];
      const selectedSection = sections[selectedSectionIndex];
      switch (selectedSection) {
        case "individualsWithSignificantAuthority":
          return (
            <>
              <Heading size="md">
                Individuals with Significant Authority
              </Heading>
              {/* Render individuals with significant authority fields here */}
            </>
          );

        case "accountInformation":
          return <AccountInformation companyType={companyType} request={request} setRequest={setRequest} />;
        case "businessInformation":
          return <BusinessInformation companyType={companyType} request={request} setRequest={setRequest} />;
        case "articles":
          return <Articles request={request} setRequest={setRequest} companyType={companyType} />;
        case "agentForService":
          return <AgentForService request={request} setRequest={setRequest} />;

        case "directors":
          return <DirectorsAndOfficersInfo request={request} setRequest={setRequest} companyType={companyType} />;
        case "officers":
          return <OfficersInfo request={request} setRequest={setRequest} />;
        case "shareholders":
          return <ShareHolders request={request} setRequest={setRequest} />;

        // case "shareStructure":
        //   return <ShareStructure request={request} setRequest={setRequest} />;

        case "minuteBook":
          return <MinuteBook request={request} setRequest={setRequest} companyType={companyType} />;
        case "bylaws":
          return <Bylaws request={request} setRequest={setRequest} />;
        case "unanimousShareholderAgreement":
          return <UnanimousShareholderAgreement request={request} setRequest={setRequest} />;

        case "typeOfPartnership":
          return <Type setRequest={setRequest} handleNext={handleNext} />;
        case "partners'Information":
          return <PartnersInfo request={request} setRequest={setRequest} companyType={companyType} />;
        case "partnershipAgreement":
          return <Agreement request={request} setRequest={setRequest} />;

        case "addOns":
          return <AddOns request={request} setRequest={setRequest} />;
        
        case "review":
          return <Review request={request} setRequest={setRequest} companyType={companyType} handleNext={handleNext} />;
        case "payment":
          return <Payment request={request} setRequest={setRequest} companyType={companyType} />;
        
          default:
          return null;
      }
    } else {
      return null; // Return null if companyType is not found in sectionsMap
    }
  };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect(() => {
  //   console.log('bType', bType);
  //   console.log('companyType', companyType);
  //   console.log('request', request);
  // });

  return (
    <Center mt="40px">
      <VStack align="left" justifyContent="center" className="container">
        {width > 550 ? (
          <HStack>
            <Heading as="h1">My business will be a</Heading>
            <Select
              fontSize="20px"
              value={companyType}
              onChange={handleTypeChange}
              w="300px"
              align="center"
              mt="-12px"
            >
              <option value="named">Named Corporation</option>
              <option value="numbered">Numbered Corporation</option>
              <option value="partnership">Partnership</option>
              <option value="soleProprietorship">Sole Proprietorship</option>
            </Select>
            <Heading as="h1" mt="-12px !important">based in AB</Heading>
          </HStack>
        ) : (
          <>
            <Text fontWeight={"bold"} mb="-10px">
              Business Type:
            </Text>
            <Select
              width="100%"
              value={companyType}
              onChange={handleTypeChange}
              align="center"
            >
              <option value="named">Named Corporation</option>
              <option value="numbered">Numbered Corporation</option>
              <option value="partnership">Partnership</option>
              <option value="soleProprietorship">Sole Proprietorship</option>
            </Select>
          </>
        )}

        <Divider className="divider" />

        {companyType !== "" && (
          <Flex w="100%">
            {/* Sidebar */}
            <Box
              className="sidebar"
              w="25%"
            >
              <VStack align="start">
                {sectionsMap[companyType]?.map((section, index) => (
                  <Button
                    key={index}
                    onClick={() => setSelectedSectionIndex(index)}
                    variant={selectedSectionIndex === index ? "solid" : "ghost"}
                    bg={selectedSectionIndex === index ? "green.700" : ""}
                    w="100%"
                    color={selectedSectionIndex === index ? "white" : ""}
                    m={0}
                    _hover={{
                      bg:
                        selectedSectionIndex === index
                          ? "green.700"
                          : "green.400",
                      color: "white", // Set text color for better contrast
                    }}
                  >
                    {section
                      .replace("account", "personal")
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .replace(/^\w/, (c) => c.toUpperCase())}{" "}
                  </Button>
                ))}
              </VStack>
            </Box>

            {/* Main bar */}
            <Box w="75%" className="maincomponent">
              {renderMainComponent()}
              {/* Previous and Next Button */}

                <Flex className="nextprevious">
                  <Center width="100%">
                    {selectedSectionIndex !== 0 && (
                      <Button
                        className="btn-black"
                        leftIcon={<ArrowLeftIcon />}
                        onClick={handlePrevious}
                        aria-label="Previous"
                        mr={2}
                        disabled={
                          selectedSectionIndex === 0 || !sectionsMap[companyType]
                        }
                      >
                        Previous
                      </Button>
                    )}
                    {selectedSectionIndex <
                      sectionsMap[companyType]?.length - 1 && (
                      <Button
                        className="btn-black"
                        rightIcon={<ArrowRightIcon />}
                        onClick={handleNext}
                        aria-label="Next"
                        disabled={
                          selectedSectionIndex ===
                            sectionsMap[companyType]?.length - 1 ||
                          !companyType ||
                          !sectionsMap[companyType]
                        }
                      >
                        Next
                      </Button>
                    )}
                  </Center>
                </Flex>

            </Box>
          </Flex>
        )}
      </VStack>
    </Center>
  );
};

export default CompanyComponent;
